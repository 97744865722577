<style lang="less" scoped>
/deep/ .el-dialog__body {
    padding: 10px 20px;
}
.menu_box {
    margin-top: 20px;
    /deep/ .el-cascader {
        width: 100%;
    }
}
/deep/ .el-main {
    padding: 0;
    padding-top: 20px;
}
.list_box {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.fiss_content {
    flex: 1;
    height: 0;
    overflow: hidden auto;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
}
.title {
    color: #334681;
    font-weight: bold;
}
.select_content {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .select_box {
        width: 140px;
        // padding: 0 4px;
        box-sizing: border-box;
        + .select_box {
            margin-left: 10px;
        }
    }
}
.statistics {
    font-size: 12px;
    margin-left: 40px;
}
.preview_group {
    display: flex;
    flex-wrap: wrap;
}
.preview_list {
    padding: 10px;
    .preview_box {
        width: 220px;
        height: 280px;
        display: flex;
        flex-direction: column;
        border: 1px solid #e8ecef;
        border-radius: 4px;
        box-sizing: border-box;
        &:hover .check {
            display: block;
        }
        &.selected {
            border-color: #349ffd;
            border-width: 2px;
            .check {
                display: block;
                background-color: #349ffd;
            }
        }
    }
    .img_box {
        flex: 1;
        background-image: url(../../assets/ma.png);
        background-size: 100% 100%;
        position: relative;
        height: 0;
        .tag_box {
            position: absolute;
            right: 0;
            top: 0;
            padding: 6px;
            z-index: 99;
        }
        .img_group {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 34px;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            .img_list {
                width: 34px;
            }
        }
    }
    .info {
        padding: 4px 10px;
        height: 64px;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        ul {
            display: flex;
            justify-content: space-between;
        }
    }
}
.check {
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    border-radius: 8px;
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 999;
    background-color: #c8cfd4;
    color: #fff;
    display: none;
}
/deep/ .el-dropdown {
    font-size: 12px;
}
.foo_bar {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .selects {
        display: flex;
        align-items: center;
    }
    .selects_item {
        padding: 0 10px;
    }
}
.iconfont {
    vertical-align: middle;
    font-size: 14px;
}
.icon-keshangxian {
    color: #56df89;
}
.icon-beiyong {
    color: #349ffd;
}
.icon-xuxiugai {
    color: #ff8670;
}
.icon-lujing1 {
    color: #ff6767;
}
.icon-shenhejilu {
    color: #ab78ff;
}
</style>
<template>
    <div class="list_box">
        <div class="fiss_content">
            <div class="fiss_hd">
                <div class="title">合成下载</div>
                <div class="select_content">
                    <div class="select_box">
                        <el-select
                            v-model="select_val1"
                            placeholder="请选择"
                            size="mini"
                        >
                            <el-option
                                v-for="item in select_data1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select_box">
                        <el-select
                            v-model="select_val2"
                            placeholder="请选择"
                            size="mini"
                        >
                            <el-option
                                v-for="item in select_data2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="statistics">
                        <span>已合成</span>
                        /
                        <span>总数</span>
                        ：
                        <span>2</span>
                        /
                        <span>10</span>
                    </div>
                    <div class="git_buttom" style="margin-left: auto">
                        <el-button
                            type="primary"
                            size="mini"
                            @click="showMenuModel"
                            >保存</el-button
                        >
                    </div>
                </div>
            </div>
            <div class="fiss_body">
                <div class="preview_group">
                    <div
                        class="preview_list"
                        v-for="(item, index) in fiss_data"
                        :key="index"
                        @click="change_preview(index)"
                    >
                        <div
                            class="preview_box"
                            :class="item.checked ? 'selected' : ''"
                        >
                            <div class="img_box">
                                <div class="tag_box">
                                    <div class="tag_list" v-if="item.savad">
                                        <el-tag type="success" size="mini"
                                            >已合成</el-tag
                                        >
                                    </div>
                                </div>
                                <div class="check">
                                    <i class="el-icon-check"></i>
                                </div>
                                <el-image
                                    style="width: 100%; height: 100%"
                                    :src="imgUrl + item.child[0].coverUrl"
                                    fit="contain"
                                ></el-image>
                                <div class="img_group">
                                    <div
                                        class="img_list"
                                        v-for="(k, i) in item.child"
                                        :key="i"
                                    >
                                        <el-image
                                            style="width: 100%; height: 100%"
                                            :src="imgUrl + k.coverUrl"
                                            fit="contain"
                                        ></el-image>
                                    </div>
                                </div>
                            </div>
                            <div class="info">
                                <div>{{ item.title }}</div>
                                <ul>
                                    <li>尺寸：{{ item.x }} * {{ item.y }}</li>
                                    <!-- <li>时长：48s</li> -->
                                </ul>
                                <ul>
                                    <li>
                                        <span v-if="item.status == 1"
                                            >合成中</span
                                        >
                                        <span v-else-if="item.status == 2"
                                            >已合成</span
                                        >
                                        <span v-else>待合成</span>
                                    </li>
                                    <li>
                                        <el-dropdown @command="upState">
                                            <div class="el-dropdown-link">
                                                <span v-if="item.videoStatus">
                                                    <i
                                                        class="iconfont"
                                                        :class="
                                                            previewStatus[
                                                                item.videoStatus -
                                                                    1
                                                            ].icon
                                                        "
                                                    ></i>
                                                    {{
                                                        previewStatus[
                                                            item.videoStatus - 1
                                                        ].label
                                                    }}
                                                </span>
                                                <span v-else>修改状态</span>
                                                <i
                                                    class="el-icon-arrow-down el-icon--right"
                                                ></i>
                                            </div>
                                            <el-dropdown-menu
                                                slot="dropdown"
                                                class="dropdown-menu"
                                            >
                                                <el-dropdown-item
                                                    v-for="(
                                                        state, stateIndex
                                                    ) in previewStatus"
                                                    :key="stateIndex"
                                                    :command="`${item.id},${state.value},${index}`"
                                                    ><i
                                                        class="iconfont"
                                                        :class="state.icon"
                                                    ></i
                                                    >{{
                                                        state.label
                                                    }}</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="foo_bar">
            <div class="page-box">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="current"
                    :page-size="where.pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
            <div class="selects">
                <div class="selects_item">
                    已选：<span class="select_num">{{
                        select_data.length
                    }}</span>
                </div>
                <el-button type="primary" size="mini">全选</el-button>
            </div>
        </div>
        <!-- 创意库目录 -->
        <!-- <el-drawer
            title="选择存放位置"
            :visible.sync="showMenu"
            direction="ltr"
        >
            <div class="tree-box">
                <MENU :material="'mev'" :type="1" @senData="gitFission" />
            </div>
        </el-drawer> -->
        <!-- 保存路径选择 -->
        <el-dialog
            title="选择目录"
            :visible.sync="showMenu"
            width="430px"
            :before-close="handleClose"
        >
            <div>
                点击【确定】将根据每个视频创意的合成配置进行合成批量合成时间可能较长，请耐心等待。
            </div>
            <div class="menu_box">
                <el-cascader
                    v-model="menuPath"
                    size="small"
                    :options="menus"
                    :props="menuProps"
                    clearable
                    @change="changeMenu"
                    ref="select"
                ></el-cascader>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMenu = false" size="small"
                    >取 消</el-button
                >
                <el-button type="primary" size="small" @click="gitFission"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- <el-cascader
    :options="options"
    :props="{ checkStrictly: true }"
    clearable></el-cascader> -->
    </div>
</template>

<script>
import {
    getPreviewList,
    getTimeLine,
    generate,
    setPreviewState,
} from "@/api/engi.js";

import { getMuenTree } from "@/api/api.js";
import MENU from "@/components/work/ku_menu";
import HEADER from "@/components/top";
export default {
    name: "FissList",
    components: {
        // MENU,
    },
    data() {
        return {
            showMenu: false, //是否显示选择目录弹框
            menus: [], //目录
            menuPath: [],
            menuProps: {
                checkStrictly: true,
                value: "id",
            },
            total: 0,
            pageSize: 20,
            current: 1,
            select_val1: "0",
            select_data1: [
                {
                    value: "0",
                    label: "全部时长",
                },
                {
                    value: "1",
                    label: "< 30s",
                },
                {
                    value: "2",
                    label: "30s - 60s",
                },
                {
                    value: "3",
                    label: "> 60s",
                },
            ],
            select_val2: "0",
            select_data2: [
                {
                    value: "0",
                    label: "全部标签",
                },
                {
                    value: "1",
                    label: "超级推荐",
                },
                {
                    value: "2",
                    label: "推荐",
                },
                {
                    value: "3",
                    label: "过审高",
                },
                {
                    value: "4",
                    label: "差异大",
                },
            ],
            imgUrl: this.$store.state.pic,
            fiss_data: [],
            select_data: [],
            where: {
                projectId: "",
                pageSize: 10,
                currentPage: 1,
                status: 0,
            },
            previewStatus: [
                {
                    label: "可上线",
                    value: 1,
                    icon: "icon-keshangxian",
                },
                {
                    label: "备用",
                    value: 2,
                    icon: "icon-beiyong",
                },
                {
                    label: "需修改",
                    value: 3,
                    icon: "icon-xuxiugai",
                },
                {
                    label: "不上线",
                    value: 4,
                    icon: "icon-lujing1",
                },
                {
                    label: "审核记录",
                    value: 5,
                    icon: "icon-shenhejilu",
                },
            ],
        };
    },

    mounted() {
        let projectId = this.$route.params.pojectId;
        if (projectId) {
            this.where.projectId = projectId;
            let where = this.where;
            this.getPreviewList(where);
        }
    },

    methods: {
        upState(val) {
            let status = val.split(",");
            let upIndex = status[2];
            let data = {
                id: status[0],
                state: status[1],
            };
            const loading = this.$loading({
                lock: true,
                text: "正在修改状态",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            setPreviewState(data).then((res) => {
                loading.close();
                if (res.code === 200) {
                    this.$notify.success("修改成功");
                    this.fiss_data[upIndex].videoStatus = status[1];
                } else {
                    this.$notify.error("修改失败");
                }
            });
        },
        changeMenu(val) {
            this.$refs.select.dropDownVisible = false;
        },
        async showMenuModel() {
            if (!this.select_data.length)
                return this.$message.error("请选择要保存的视频");
            this.showMenu = true;
            let json = {
                materialLibraryType: 1,
                "orders[0].column": "create_time",
            };
            let res = await getMuenTree(json);
            // console.log(res);
            if (res.code == 200) {
                this.menus = res.data;
            }
        },
        handleSizeChange() {},
        handleCurrentChange(page) {
            this.where.currentPage = page;
            this.getPreviewList(this.where);
        },
        //
        getPreviewList(where) {
            getPreviewList(where).then((res) => {
                let list = res.data.list;
                list.forEach((item) => {
                    let isSelect = this.select_data.findIndex(
                        (check) =>
                            check.previewCatalogueSort ===
                            item.previewCatalogueSort
                    );
                    if (isSelect > -1) {
                        item.checked = "checked";
                    }
                });
                this.fiss_data = res.data.list;
                this.total = res.data.pagination.total;
            });
        },
        change_preview(index) {
            let checked = true;
            if (this.fiss_data[index].checked) {
                // 取消选择
                checked = false;
                let selectIndex = this.select_data.findIndex(
                    (res) =>
                        res.previewCatalogueSort ==
                        this.fiss_data[index].previewCatalogueSort
                );
                this.select_data.shift(selectIndex, 1);
            } else {
                // 选中
                this.select_data.unshift(this.fiss_data[index]);
            }
            this.$set(this.fiss_data[index], "checked", checked);
            // console.log(this.fiss_data[index]);
        },
        async gitFission() {
            // 目录ID
            let mid = this.menuPath[this.menuPath.length - 1];
            if (!mid) return this.$message.error("请选择保存路径");
            let loading = this.$loading({
                lock: true,
                text: "正在提交合成任务",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            // 生成TimeLine
            let solts = [];
            //
            let select_data = this.select_data;
            select_data.forEach((item) => {
                // let json = {};
                // json.previewCatalogueSort = item.previewCatalogueSort;
                solts.push(item.id);
            });
            let data = {
                previewIdList: solts,
                projectId: this.where.projectId,
            };
            // console.log(data);
            let timelines = await getTimeLine(data);
            let ids = [];
            timelines.data.map((item) => {
                ids.push(item.videoPreviewId);
            });
            let generate_data = {
                // previewIdList: JSON.stringify(ids),toString
                previewIdList: ids.toString(),
                dictoryId: mid,
            };

            let generate_res = await generate(generate_data);
            loading.close();
            if (generate_res.code == 200) {
                this.showMenu = false;
                this.$message.success("提交任务成功");
            } else {
                this.$message.error("提交任务失败");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>